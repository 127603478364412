.PhoneInputInput {
    height: 35px !important;
    border-radius: 4px !important;
    border: 1px solid #d7d7d7 !important;
    padding: 8px 12px !important;
    color: #3a3a3a !important;
}
.PhoneInputInput:focus-visible {
   outline-color: rgba(20, 83, 136, 0.6) !important;
   outline-style: solid;
}

.phoneInput{
    width: 100% !important;
 }

 .invalid-input{
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: 98% calc(0.375em + 0.1875rem) !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
 }

 .invalid-input::placeholder{
   color: #db616dd7 !important;
}

 .valid-input{
   border-color: #28a745 !important;
   padding-right: calc(1.5em + 0.75rem) !important;
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
   background-repeat: no-repeat !important;
   background-position: center right calc(0.375em + 0.1875rem) !important;
   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
 }