.mail-group {
    height: 70% !important;
    background-color: #298fdc;
    border-radius: 20px;
}

.protection-toggle-content h6,
.mail-information-content h4 {
    font-size: 17px;
    color: rgb(245, 240, 240) !important;
    padding-top: 5px;
}

.setting-box-content {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 12vw;
    white-space: initial;
    --templateColor: transparent;
    border: 1px solid #0b4e82;
    padding: 7px;
    background: transparent;

}

.setting-box-content:hover {
    background-color: #278edc38
}

.setting-box-content:focus {
    border: 1px solid #0b4e82;
    background-color: #ffff;
}

.setting-box-content h5 {
    font-size: 15px;
    color: rgb(245, 240, 240) !important;
}

.setting-box-content i {
    font-size: 20px;
    color: rgb(245, 240, 240) !important;
}

.protection-box-disable-content {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 12vw;
    white-space: initial;
    --templateColor: transparent;
    border: 1px solid #d11a11;
    padding: 7px;
    background: transparent;
}

.protection-box-disable-content:hover {
    background-color: #d11b112f
}

.protection-box-disable-content:focus {
    background-color: #ffff;
    border: 1px solid #d11a11;
}

.set-all-active-button {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 100px;
    white-space: initial;
    --templateColor: transparent !important;
    border: 1px solid rgb(3, 142, 3) !important;
    padding-top: 4px !important;
    background: transparent;
}

.set-all-active-button:hover {
    background-color: rgba(3, 142, 3, 0.237) !important;
}

.set-all-active-button:focus {
    border: 1px solid rgb(3, 142, 3) !important;
    background-color: #ffff !important;
}

.set-all-inactive-button {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 100px;
    white-space: initial;
    --templateColor: transparent !important;
    border: 1px solid #d11a11 !important;
    padding-top: 4px !important;
    background: transparent;
}

.set-all-inactive-button:hover {
    background-color: #d11b112f !important;
}

.set-all-inactive-button:focus {
    background-color: #ffff !important;
    border: 1px solid #d11a11 !important;
}

.protection-enabled-state-text {
    display: flex;
    text-align: center;
}

.protection-enabled-state-text h4 {
    color: #008000;
    font-size: 18px;
}

.icon-flex {
    display: flex;
    align-items: center;
    color: rgb(3, 142, 3);
    padding: 5px 15px 0 0;
    width: 14vw
}

.enable-icon-flex {
    font-size: 26px;
    color: rgb(3, 142, 3) !important;
    margin-left: 5px;
}

.icon-flex h4 {
    font-size: 17px;
    color: #000;
    /* margin-left: 50%; */
}

.icon-flex h5 {
    color: #000;
}

.icon-flex i {
    font-size: 26px;
    margin-top: -8px;
    color: #d11a11;
}

.disable-icon-flex {
    font-size: 26px;
    color: #d11a11 !important;
    margin-left: 5px;
}

.shield-icon {
    font-size: 23px;
    margin-top: -8px;
    margin-bottom: 8px !important;
    margin-left: 5px !important;
    color: #d11a11;
}

.protection-enabled-enable-text i {
    color: rgb(3, 142, 3);
    font-size: 23px;
    margin-bottom: 8px !important;
    margin-left: 5px !important;
}

.protection-enabled-disable-text h5 {
    letter-spacing: .5px;
    font-size: 17px;
    font-weight: 600;
}

.protection-enabled-enable-text h5 {
    letter-spacing: .5px;
    font-size: 17px;
    font-weight: 600;
}

.protection-box-content {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    width: 12vw;
    white-space: initial;
    --templateColor: transparent;
    border: 1px solid rgb(3, 142, 3);
    padding: 7px;
    background: transparent;
}

.protection-box-content:hover {
    background-color: rgba(3, 142, 3, 0.237)
}

.protection-box-content:focus {
    border: 1px solid rgb(3, 142, 3);
    background-color: #ffff;
}

.setting-box {
    width: 10vw;
    background-color: #0b4e82;
    border-radius: 10px;
    margin-left: -15px;
}

.mail-group {
    height: 70% !important;
    background-color: #298fdc;
    border-radius: 20px;
}

#myChart {
    width: 100%;
    height: 30vh;
}

.hr-bottom-line {
    color: #278FDC;
    background-color: #278FDC;
    height: 1;
    width: 70%;
    border-color: #278FDC
}

.mail-information-box {
    border-right: .5px solid rgb(181, 181, 181);
}

.mail-information-box:last-child {
    border: none;
}

.mail-information-content {
    text-align: center;
    padding-top: 3px;
}

.mail-information-content h5 {
    font-size: 25px;
    color: white;
    line-height: .2rem;
    padding: 17px;
    font-weight: 600;
}

.mail-information-content h6 {
    font-size: 14px;
    line-height: .01rem;
    color: white;
    margin-bottom: 15px;
}

.graph-bloc-tabs {
    display: flex;
    width: 100% !important;
}


.graph-bloc-tabs button {
    border: none;
}


.phish-count-tab {
    background: #b3221a;
    border-bottom: 1px solid transparent;
    color: #fff;
}

.total-count-tab {
    background: #0b4e82;
    border-bottom: 1px solid transparent;
    color: #fff;
}


.graphTabs {
    text-align: center;
    cursor: pointer;
    outline: none;
    border-right: 3px solid blue;
    background-color: #fbfbfb;

}

.graphTabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.last-incidents-title {
    padding: 10px;
    padding-top: 8px;
    background-color: #0b4e82;
    color: white;
    text-align: center;
}

.mail-phish-urls-title {
    padding: 5px;
    padding-top: 4px;
    color: #0b4e82;
    text-align: center;
}

.incidents-click-more {
    border: none;
    outline: none;
    font-size: 12px;
    color: #a1a1a1 !important;
    background-color: transparent;
    font-weight: 500 !important;
}

.incidents-click-more:hover {
    margin-top: 5px;
    transition: 0.5s all;
}

.incidents-table-body td:first-child {
    padding-left: 0;
}

.incidents-table-body td:last-child {
    padding-right: 2px;
}

.incidents-detail-card:hover {
    background-color: rgba(180, 223, 255, 0.76);
}

.recipients-table .recipients-table-body {

    border-spacing: 0px 2rem;
    ;

    tr {
        th {
            padding-top: 10px;
            padding-bottom: 7px;
        }
    }
}

.fieldsetMaster {
    width: 500px;
    height: 300px;
    text-align: center;
    position: relative;
    border: 0.4px #278FDC;
    border-style: solid;
    padding: 10px;

}



.fieldsetMaster h1 {
    position: absolute;
    top: 0;
    font-size: 20px;
    line-height: 1;
    margin: -34px 0 0;
    /* half of font-size */
    padding: 0 3px;
    background: #f8f8f8;
}
