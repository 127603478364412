.document-login-table {

    tr {
        font-size: 17px;
        text-align: center;

        a {
            text-decoration: underline
        }

        &.warning-row{
            background: #FEF2D6
            
        }

    }

    td{
        &.icon-cell{
            justify-content: center;
             display: flex;
        }
    }


}

.icon-cell-div-element{
    background: white;
     padding: 5px;
     width: 30px;
     border-radius: 5px
}

.document-title {
    font-size: 35px;
    font-weight: 800
}

.hr-underline {
    color: #278FDC;
    background-color: #278FDC;
    margin-top: 0;
    height: 1;
    float: left;
    border-color: #278FDC
}

.success-description {
    background-color: #EBF5EF;
    border-left: 5px solid #008944;
    padding: 10px;
    min-height: 70px;
    display: flex;

    p {
        margin-top: 5px;
        font-size: 17px;
        margin-top: auto
    }
}

.warning-description {
    background-color: #FDF9EA;
    border-left: 5px solid #F8AE00;
    padding: 10px;
    min-height: 70px;
    display: flex;

    p {
        margin-top: 5px;
        font-size: 17px;
        margin-top: auto
    }
}

.info-description {
    background-color: #ECF6FD;
    border-left: 5px solid #1390E0;
    padding: 10px;
    min-height: 70px;
    display: flex;

    p {
        margin-top: 5px;
        font-size: 17px;
        margin-top: auto
    }
}
.document-sub-title{
    font-size: 24px;
    font-weight: 700;
}

.document-sub-title-1{
    font-size: 20px;
    font-weight: 700;
}

.list-item-bold{
    font-size: 18px;
    font-weight:700
}

.doc-nav-button{
    background-color: #fff;
    border-color: #145388;
    border:none;
    border-radius: 50px;
    font-size: 15px;
    height: 40px;
    transition: ease-in-out .2s;
    text-align: left;
    padding-left: 16px;
    color: #3a3a3a;

    &.active {
        background-color: #145388 !important;
        border-color: #145388 !important;
        color: #fff !important;
    }
}